/* This stylesheet generated by Transfonter (http://transfonter.org) on December 3, 2016 12:25 AM */

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Text-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('SF-Pro-Display-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten';
  src: url('Tungsten-Light.woff') format('woff'),
    url('Tungsten-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten Book';
  src: url('Tungsten-Book.woff') format('woff'),
    url('Tungsten-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten';
  src: url('Tungsten-Medium.woff') format('woff'),
    url('Tungsten-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Tungsten';
  src: url('Tungsten-Semibold.woff') format('woff'),
    url('Tungsten-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten';
  src: url('Tungsten-Bold.woff') format('woff'),
    url('Tungsten-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten';
  src: url('Tungsten-Black.woff') format('woff'),
    url('Tungsten-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
