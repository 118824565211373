@import '../../styles/mixins.scss';

.Dashboard {
  &-content {
    @include content;

    height: auto;

    @keyframes contentScroll {
      0% {
        transform: translateY(0);
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      2% {
        transform: translateY(0);
      }
      98% {
        transform: translateY(calc(-100% + (100vh - 15rem)));
      }
      99% {
        opacity: 1;
      }
      100% {
        transform: translateY(calc(-100% + (100vh - 15rem)));
        opacity: 0;
      }
    }
    @keyframes contentBounce {
      0%,
      5% {
        transform: translateY(0);
      }
      48%,
      53% {
        transform: translateY(calc(-100% + (100vh - 15rem)));
      }
      95%,
      100% {
        transform: translateY(0);
      }
    }
  }
  &-activity {
    @include bottom;
  }
}
