@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';

.Concerns {
  @include sub-card-layout;
  background-color: $dark-gray;

  &-num {
    @include sub-card-num;
    @include sentence-case;
  }

  hr {
    margin-bottom: $sp-xlarge;
  }
}
