@import '../../styles/spacing.scss';
@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

.Counter {
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 6px;
  color: $white;
  margin: $sp-small $sp-normal $sp-small 0;
  text-align: left;
  padding: $sp-small;

  &--default {
    background-color: $blue;
  }

  &--noData {
    background-color: $gray;
  }

  &--success {
    background-color: $green;

    &-index0 {
      background-color: $green;
    }

    &-index1 {
      background-color: rgba($green, 0.75);
    }

    &-index2 {
      background-color: rgba($green, 0.5);
    }
  }

  &--error {
    background-color: $orange;

    &-index0 {
      background-color: $orange;
    }

    &-index1 {
      background-color: rgba($orange, 0.7);
    }

    &-index2 {
      background-color: rgba($orange, 0.45);
    }
  }

  &-count {
    font-family: $ff-secondary;
    font-size: $fs-xlarge;
    letter-spacing: 1px;
  }

  &-desc {
    text-transform: uppercase;
    font-size: $fs-xsmall;
    letter-spacing: 1px;
  }
}
