@import '../../../../styles/mixins.scss';

.Bottom {
  @include bottom;

  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  @keyframes bottomScroll {
    0% {
      -moz-transform: translateX(100vw);
      -webkit-transform: translateX(100vw);
      transform: translateX(100vw);
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
}
