@import '../../styles/spacing.scss';
@import '../../styles/fonts.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

$radius: 4px;

.Risk {
  width: 100%;
  display: flex;
  border-radius: $radius;
  box-shadow: 0 2px 4px 0 rgba($black, 0.2);
  background-color: $white;
  margin: $sp-small 0;

  &-avatar {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;

    img {
      height: 40px;
      border-radius: 50%;
      margin: $sp-small;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
  }

  &-summary {
    @include sentence-case;
    color: $gray;
    opacity: 0.5;
    margin-top: 0;
    width: 16rem;
  }

  &-authors {
    font-size: $fs-normal;
    color: $gray;
    margin-bottom: $sp-small;
    text-transform: capitalize;
  }

  &-severity {
    border-radius: $radius 0 0 $radius;
    width: 0.5rem;

    &--high {
      background-color: $orange;
    }
    &--medium {
      background-color: lighten($orange, 10%);
    }
    &--low {
      background-color: $yellow;
    }
  }
}
