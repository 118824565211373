@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';

$for-approval: $blue;
$on-schedule: $green;
$behind: $orange;
$at-risk: $yellow;
$radius: 10px;

@mixin chart-layout {
  display: flex;
  align-items: center;
  height: 180px;
  justify-content: flex-end;
  position: relative;
  background-color: $medium-gray;
}

%chart-wrapper {
  right: 5px;
  bottom: 5px;

  svg {
    height: 132px;
    width: 132px;
  }
}

.Chart {
  &-for-approval {
    @include chart-layout;

    .recharts-sector {
      stroke: $for-approval;
    }

    .recharts-wrapper {
      @extend %chart-wrapper;
    }
  }

  &-on-schedule {
    @include chart-layout;

    .recharts-sector {
      stroke: $on-schedule;
    }

    .recharts-wrapper {
      @extend %chart-wrapper;
    }
  }

  &-behind {
    @include chart-layout;

    .recharts-sector {
      stroke: $behind;
    }

    .recharts-wrapper {
      @extend %chart-wrapper;
    }
  }

  &-overdue {
    @include chart-layout;

    .recharts-sector {
      stroke: $behind;
    }

    .recharts-wrapper {
      @extend %chart-wrapper;
    }
  }

  &-at-risk {
    @include chart-layout;

    .recharts-sector {
      stroke: $at-risk;
    }

    .recharts-wrapper {
      @extend %chart-wrapper;
    }
  }

  &-status {
    margin: $sp-small 0 0 0;
    position: absolute;
    top: 54px;
    left: 46px;
  }

  &-back {
    margin: $sp-small 0 0 0;
  }
}
