$ff-primary: 'SF-Pro-Display', sans-serif;
$ff-secondary: 'Tungsten', sans-serif;

$fs-root: 15px;
$fs-xsmall: 0.75rem;
$fs-small: 0.875rem;
$fs-normal: 1rem;
$fs-medium: 1.25rem;
$fs-large: 1.5rem;
$fs-header: 2rem;
$fs-xlarge: 2.5rem;

$icon-small: 1.5rem;
$icon-normal: 2rem;
$icon-large: 2.5rem;
$icon-xlarge: 3rem;
