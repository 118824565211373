@import '../../../../styles/mixins.scss';

.Version {
  &-overview {
    @include sub-card-layout;
  }

  &-info {
    @include sub-card-layout;
  }

  &-num {
    @include sub-card-num;
  }

  &-details {
    @include sub-card-details;
  }
}
