@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/vars.scss';

.Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $gray;
  height: 6rem;
  width: 100%;
  padding: 0 $sp-xxlarge;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media screen and (max-width: $mobile) {
    display: none;
  }

  &-chrono {
    display: flex;
    align-items: center;
  }

  &-logo {
    height: 2rem;
  }

  &-time {
    margin: 0 $sp-large 0 0;
  }

  &-day {
    letter-spacing: 1px;
    text-align: right;
    font-weight: 300;
  }
}
