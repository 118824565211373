@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/fonts.scss';

.Activity {
  display: flex;
  padding: $sp-large 0 $sp-large $sp-large;
  width: 380px;
  height: 100px;
  background-color: $dark-gray;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: $sp-xlarge $sp-xlarge $sp-normal $sp-xlarge;
  box-shadow: rgba($black, 0.1) 0 1px 2px 1px;

  &:not(:last-child) {
    margin-right: 0;
  }

  &-initial {
    text-align: center;
    color: $white;
    font-size: $fs-header;
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: $white;
    width: 80%;
  }

  &-timestamp {
    display: flex;
    align-self: baseline;
    text-align: right;
    color: $white;
    width: 20%;
    opacity: 0.5;
    margin-right: $sp-normal;
    font-size: $fs-small;
  }

  &-status {
    display: flex;
    align-items: center;
    margin-bottom: $sp-small;
    justify-content: flex-start;

    button {
      margin: 0;
      white-space: nowrap;
    }
  }

  &-issue {
    color: $white;
    font-size: $fs-medium;
    text-align: center;
    margin: 0 $sp-normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-actor {
    opacity: 0.5;
    font-size: $fs-small;
  }

  &-description {
    margin: $sp-small 0;
    font-size: $fs-small;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    overflow: hidden;
  }
}
