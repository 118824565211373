@import './spacing.scss';
@import './fonts.scss';
@import './colors.scss';
@import './vars.scss';

@mixin sub-card-layout {
  padding: $sp-large;
  background-color: $medium-gray;
}

@mixin sub-card-num {
  font-size: $fs-large;
  opacity: 0.8;
  margin-top: $sp-small;
  font-weight: 300;
}

@mixin sub-card-details {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  padding: $sp-xlarge 0 $sp-normal 0;
  font-weight: 500;
}

@mixin counter-title {
  opacity: 0.8;
  font-size: $fs-small;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

@mixin sentence-case {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin title {
  font-size: $fs-xlarge;
  border-bottom: 1px $black;
  letter-spacing: 0.75px;
  padding-bottom: $sp-normal;
  margin-bottom: $sp-xlarge;
  border-bottom: 1px solid $light-gray;
}

@mixin center-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 20rem;
}

@mixin background {
  background-color: $dark-gray;
}

@mixin bottom {
  background-color: $gray;
  height: 9.8rem;
  width: 100%;
  margin-top: $sp-normal;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $sp-xlarge;
  overflow: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

@mixin content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  /* calculations below are used for dashboard scroll effect */
  height: calc(100vh - 7rem - 9.7rem);
  overflow-y: auto;
  margin-top: 7rem;
  margin-bottom: 9.7rem;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
