@import './styles/colors.scss';
@import './styles/spacing.scss';
@import './styles/fonts.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: lighten($light-gray, 5%);
  font-family: $ff-primary;
  font-size: $fs-root;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  color: $dark-gray;
}

button {
  color: $white;
  border: none;
  padding: $sp-small;
  font-size: $fs-normal;
}

input {
  text-transform: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ff-secondary;
  font-weight: normal;
  text-transform: uppercase;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

fieldset {
  border: none;
}

select,
textarea,
input:not([type='checkbox']),
input[type='text'],
input[type='search'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='tel'],
input[type='color'] {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: $sp-normal;
  margin-bottom: $sp-normal;
  font-family: $ff-primary;
  font-size: $fs-normal;
  background-color: $white;
  border: 1px solid lighten($gray, 50%);
  outline: none;

  &:focus {
    box-shadow: rgba($blue, 0.4) 0 0 0 2px;
  }
}

input[type='radio'] {
  width: auto;
}

label {
  margin: $sp-normal 0;
  font-family: $ff-primary;
  margin: 1rem 0;
  color: $dark-gray;
}

a {
  text-decoration: none;
  margin: $sp-normal;
  color: $dark-gray;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
  }
}
