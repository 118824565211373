@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/fonts.scss';

.Meta {
  display: flex;
  background-color: $gray;
  justify-content: flex-start;
  padding: $sp-normal;
  box-shadow: 0 7px 7px -3px rgba($black, 0.1);
  position: relative;

  &-item {
    display: flex;
    flex-direction: column;
    margin: 0 $sp-small;
  }

  &-title {
    font-size: $fs-small;
    text-transform: uppercase;
    opacity: 0.5;
    letter-spacing: 1.2px;
  }

  &-text {
    margin: $sp-small 0 0 0;
    letter-spacing: 1px;
  }

  &-lead {
    display: flex;
    align-items: center;

    img {
      height: 28px;
      border-radius: 50%;
      margin: $sp-small $sp-small 0;
    }
  }
}
