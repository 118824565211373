$black: #000000;
$white: #ffffff;
$faux-white: #f4f4f4;
$gray: #3d4d52;
$light-gray: #eaebeb;
$medium-gray: #2e3a3e;
$charcoal-gray: #a9a9a9;
$dark-gray: #21282b;
$red: #f64744;
$blue: #5299e7;
$light-blue: #cbe0f8;
$dark-blue: #437cba;
$green: #86cb6a;
$dark-green: #719561;
$light-green: #3d4c52;
$orange: #ef5f4f;
$yellow: #ebb743;
$purple: #bd7ec0;
