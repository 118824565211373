@import '../../styles/fonts.scss';
@import '../../styles/colors.scss';

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  &-text {
    color: $white;
    letter-spacing: 2px;
    font-size: $fs-xlarge;
    font-weight: 300;
  }

  progress[value] {
    width: 25rem;
  }

  progress[value]::-webkit-progress-value {
    background-color: $white;
  }

  progress[value]::-moz-progress-bar {
    background-color: $white;
  }
}
