@import '../../../../styles/spacing.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/fonts.scss';

.Title {
  display: flex;
  height: 122px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $gray;
  justify-content: flex-start;
  color: $white;
  box-shadow: 0 2px 4px 0 rgba($black, 0.1);
  border-radius: 5px 5px 0 0;
  z-index: 100;

  &-details {
    display: block;
    margin: $sp-xlarge $sp-xlarge $sp-small;
  }

  &-text {
    margin: 0;
    text-transform: capitalize;
    text-align: left;
    font-size: $fs-large;
  }

  &-label {
    display: flex;
    margin: $sp-large 0;
    align-items: center;
  }
}

button.Title-label-button {
  margin: 0;
}

.quality-icon {
  margin-right: $sp-small;
  font-size: $icon-normal;

  &--good {
    color: $green;
  }

  &--ok {
    color: $yellow;
  }

  &--bad {
    color: $red;
  }
}
