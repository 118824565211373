@import '../../../../styles/mixins.scss';

.Bugs {
  @include sub-card-layout;

  &-title {
    @include sub-card-num;
  }

  &-details {
    @include sub-card-details;

    span {
      opacity: 0.5;
    }
  }
}
