@import '../../styles/colors.scss';

.Progress {
  progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 100%;
    border-radius: 10px;
  }

  progress::-ms-fill {
    border: none;
  }

  progress[value]::-webkit-progress-bar {
    background-color: $light-green;
    border-radius: 10px;
  }

  progress,                          /* Firefox  */
  progress[role][aria-valuenow] {
    /* Polyfill */
    background-color: $light-green !important; /* !important is needed by the polyfill */
  }

  progress[value]::-webkit-progress-value {
    background-color: $green;
    border-radius: 10px;
  }

  progress[value]::-ms-fill {
    background-color: $green;
    border-radius: 10px;
  }

  progress[value]::-moz-progress-bar {
    background-color: $green;
    border-radius: 10px;
  }

  &-over {
    progress[value] {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      width: 100%;
      border-radius: 10px;
    }

    progress[value]::-webkit-progress-bar {
      background-color: $light-green;
      border-radius: 10px;
    }

    progress,                          /* Firefox  */
    progress[role][aria-valuenow] {
      /* Polyfill */
      background-color: $light-green !important; /* !important is needed by the polyfill */
    }

    progress[value]::-webkit-progress-value {
      background-color: $red;
      border-radius: 10px;
    }

    progress[value]::-moz-progress-bar {
      background-color: $red;
      border-radius: 10px;
    }
    progress[value]::-ms-fill {
      background-color: $red;
      border-radius: 10px;
    }
  }
}
