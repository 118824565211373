@import '../../../../styles/spacing.scss';
@import '../../../../styles/mixins.scss';

.Budget {
  @include sub-card-layout;
  padding: 0 $sp-xlarge $sp-xxlarge;

  &-details {
    @include sub-card-details;
    padding: 0 0 $sp-normal 0;

    span {
      opacity: 0.5;
    }
  }
}
