@import '../../../../styles/spacing.scss';
@import '../../../../styles/mixins.scss';

.UpcomingSprints {
  @include sub-card-layout;

  &-title {
    margin-bottom: $sp-large;
  }

  &-num {
    @include sub-card-num;
  }

  &-details {
    display: flex;
    justify-content: flex-start;
  }

  &-group-title {
    @include counter-title;
  }
}
