@import '../../../../styles/mixins.scss';

.Sprint {
  @include sub-card-layout;

  &-num {
    @include sub-card-num;
  }

  &-details {
    @include sub-card-details;
  }
}
