@import '../../../../styles/spacing.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';

.RemainingBugs {
  @include sub-card-layout;

  &-title {
    margin-bottom: $sp-xlarge;
  }

  &-num {
    text-transform: uppercase;
    font-size: $fs-medium;
    letter-spacing: 0.15rem;
  }

  &-details {
    display: flex;
    justify-content: flex-start;
  }

  &-group-title {
    @include counter-title;
  }
}
