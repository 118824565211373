@import '../../../../styles/fonts.scss';

@mixin timeElapsed {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 124px;
  width: 124px;
  right: 37px;
  bottom: 37px;
}

.TimeElapsed {
  &-overdue {
    @include timeElapsed;
  }

  &-remaining {
    @include timeElapsed;
  }

  &-text {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-family: $ff-primary;
    font-size: $fs-small;
    letter-spacing: 1px;
    opacity: 0.5;
  }

  &-value {
    margin: 0;
    font-size: $fs-xlarge;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 0;
  }
}
